<template>
  <div class="w_material_detail">
    <div class="left_com">
      <div class="top">
        <div class="title">
          <span class="text">{{ data.dto.en_title }}</span>
          <!-- <span class="btn">
            <img src="../../assets/images/cmf_chouc.png" />
            <span>收藏</span>
          </span> -->
        </div>

        <el-carousel class="imgbox" height="233px">
          <el-carousel-item v-for="(item, index) in data.dto.albums" :key="index">
           <viewer :images="data.dto.albums">
                  <img :key="index"  :src="item"  height="100" class="item-card">
            </viewer>
          </el-carousel-item>
        </el-carousel>

        <!-- <div class="swiper-container imgbox swiper-zoom-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(image, index) in data.dto.albums" :key="index">
              <el-image class="img" fit="cover" :src="_this.utils.HandleUrl(image, false, 1)">
                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </div>
          </div>

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div> -->
      </div>

      <div class="bot">
        <div class="desc"><span>Detailed introduction</span></div>
        <div class="descCon" v-html="data.dto.en_content"></div>
      </div>
    </div>
    <div class="right_com">
      <div class="lingyu">
        <div class="anli_item">
          <div class="label"  style="width:105px;margin-right:10px">Industry</div>
          <div class="value">{{ data.dto.en_anli_hangye ? data.dto.en_anli_hangye:'--' }}</div>
        </div>
        <div class="anli_item">
          <div class="label"  style="width:105px;margin-right:10px">Designer</div>
          <div class="value">{{ data.dto.en_designer ? data.dto.en_designer :'--' }}</div>
        </div>
        <div class="anli_item">
          <div class="label"  style="width:105px;margin-right:10px">Brand</div>
          <div class="value">{{ data.dto.en_brand ? data.dto.en_brand :'--' }}</div>
        </div>
        <div class="anli_item last">
          <div class="label"  style="width:105px;margin-right:10px">Design company</div>
          <div class="value">{{ data.dto.en_designer_company?data.dto.en_designer_company:'--' }}</div>
        </div>
        <div class="parts">
          <div class="parts_item" v-for="(item,index) in data.dto.parts" :key="index">
            <div class="parts_title">{{ item.title }}</div>
            <div class="parts_tips">材料：{{ item.material ? item.material:'--' }}</div>
            <div class="parts_tips">工艺：{{ item.craft ? item.craft :'--' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"
import { useRoute } from 'vue-router';
import { getCurrentInstance, reactive, nextTick } from 'vue';
import { Picture as IconPicture } from '@element-plus/icons-vue'

const instance = getCurrentInstance();
const _this = instance.appContext.config.globalProperties;

const $route = useRoute();
const pro_id = $route.params.id;
const data = reactive({
  dto:{}
});

function GetAnliDetail() {
  var url = "/api/cailiaoguan.ashx?action=GetAnliDetail";
  _this.$http.post(url, { id: pro_id }).then(function (res) {
    if (res.data.status == 1) {
      data.dto = res.data.data;
      data.dto.albums = res.data.data.albums.split(',');

      initSwiper();
    }
  }.bind(this)).catch(function () {
  });
}
GetAnliDetail();


function initSwiper() {
  nextTick(() => {
    new Swiper(".swiper-container", {
      zoom: true,
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  });
}

</script>